<template>
  <div>
    <div v-if="bless_loading" class="loading"></div>

    <div class="root" v-if="status === 0">
      <div class="back" v-if="step === 2">
        <img src="@/assets/message/back.png" width="20" height="20" @click="handleBack"/>
      </div>

      <div class="header" v-if="step === 1">
        <div class="preamble">
          <div class="left">
            <h2 class="title">{{ $t("feture.modal_bless") }}</h2>
            <span>{{ $t("feture.choose_form") }}</span>
          </div>
          <div class="right" @click="handleSkipLP">
            <span style="color: #282828; margin-right:5px;">{{ $t("feture.skip") }}</span>
            <img src="@/assets/message/skip.png" width="21" height="13" />
          </div>
        </div>
      </div>
      <div class="header" v-else-if="step === 2 && mode === 'picture'">
        <h2 class="title">{{ $t("feture.photo_bless") }}</h2>
        <span>{{ $t("feture.fill_wish") }}</span>
      </div>
      <div class="header" v-else-if="step === 2 && mode === 'audio'">
        <h2 class="title">{{ $t("feture.vioce_bless") }}</h2>
        <span>{{ $t("feture.fill_wish") }}</span>
      </div>
      <div class="header" v-else-if="step === 2 && mode === 'video'">
        <h2 class="title">{{ $t("feture.video_bless") }}</h2>
        <span>{{ $t("feture.fill_wish") }}</span>
      </div>

      <div class="step">
        <span>{{step}}/2</span>
        <div class="steps">
          <span :class="step === 1 ? 'active': ''"></span>
          <span :class="step === 2 ? 'active': ''"></span>
        </div>
      </div>

      <!-- 第一页 选模版 -->
      <div class="template" v-if="step === 1">
        <div class="container" :class="mode === 'picture' ? 'select': ''" @click="handleSelectMode('picture')">
          <div class="content">
            <img src="@/assets/message/picture.png" width="36" height="36" />
            <span>{{ $t("feture.photo_bless") }}</span>
          </div>
        </div>
        <!-- <div class="container" :class="mode === 'audio' ? 'select': ''" @click="handleSelectMode('audio')">
          <div class="content">
            <img src="@/assets/message/audio.png" width="36" height="36" />
            <span>{{ $t("feture.vioce_bless") }}</span>
          </div>
        </div> -->
        <div class="container" :class="mode === 'video' ? 'select': ''" @click="handleSelectMode('video')">
          <div class="content">
            <img src="@/assets/message/video.png" width="36" height="36" />
            <span>{{ $t("feture.video_bless") }}</span>
          </div>
        </div>
      </div>

      <!-- 第二页 填内容 -->
      <div class="mode" v-else>
        <fileupload v-if="mode === 'video'" :filetype="'video'" :vid="vid" @confirm="handleGetFile">
          <div class="mode-content content" slot="content">
            <img src="@/assets/message/upload_video.png" width="36" height="28" />
            <span style="color: rgba(169, 169, 176, 1)">{{ $t("feture.plse_sel_video") }}</span>
          </div>
        </fileupload>
        <fileupload v-else :filetype="'picture'" :vid="vid" @confirm="handleGetFile">
          <div class="mode-content content" slot="content">
            <img src="@/assets/message/photos.png" width="36" height="28" />
            <span style="color: rgba(169, 169, 176, 1)">{{ $t("feture.plse_sel_photo") }}</span>
          </div>
        </fileupload>

        <!-- <fileupload v-if="mode === 'audio'" :filetype="'audio'" :vid="vid" @confirm="handleGetFile">
          <div class="content audio-content" slot="content">
            <img src="@/assets/message/record.png" width="40" height="55" />
            <span style="color: rgba(169, 169, 176, 1)">{{ $t("feture.plse_add_voice") }}</span>
          </div>
        </fileupload>
        <div v-else /> -->

        <div>
          <input class="bless-name" v-model="bless_name" :placeholder="$t('feture.please_title')" />
          <textarea class="container bless" v-model="bless_text" :placeholder="$t('feture.please_content')" />
        </div>
      </div>

      <div class="button" v-if="step === 1" @click="hanldNext">{{ $t("feture.next") }}</div>
      <div class="button" v-else @click="handleSubmit">{{ $t('feture.smt') }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import callApp from "@/services/sdk";
import { storage } from '@/utils';
import { getBlessSubmitInfo } from '@/utils/bless';
import VeTrueApi from '@/api/vetrue';

import fileupload from '@/feature/components/fileupload.vue';

// 创建寄语
export default Vue.extend({
  name: 'Message',
  components: {
    fileupload,
  },
  data: () => ({
    step: 1,
    mode: '',
    vid: '',
    file: {},
    bless_name: '',
    bless_text: '',
    videoInfo: {},
    imageInfo: {},
    audioInfo: {},
    status: 1, // 0 表示未创建, 用1来初始化时不显示空白内容，因为跳转会先显示bless页面
    bless_loading: false,
  }),
  computed: {

  },
  async mounted() {
    let vid = this.$route.query.vid;
    const params_d = this.$route.query.d || '';
    const params_m = this.$route.query.m || '';

    // HIGH 对于导出上链接只有 d 的情况(和nfc读取出来的url一致)，需获取vid，并且可能出现错误情况，就不往下走
    if (!vid && params_d) {
      try {
        const params = { custom_id: params_d, bu_id: params_m };
        const rst = await VeTrueApi.getVidByCustomId(params);
        vid = R.pathOr('',['data', 'vid'])(rst);
        console.log(vid, 'vid');
      } catch (error) {
        const msg = R.pathOr('',['response', 'data', 'detail'])(error);
        this.$message({ message: msg, type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return;
      }
    }
    if (params_d && !vid) {
      this.$message({ message: this.$t('feture.miss_customId'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      return;
    }

    if (!vid) {
      this.$message({ message: this.$t('feture.miss_vid'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      return;
    }

    // 如果 vid 参数并没有以 0x 开头，那么就要补充成 0x000000000000000000000000 + vid
    if (!vid.toLowerCase().startsWith('0x')) {
      this.vid = `0x000000000000000000000000${vid}`;
    } else {
      this.vid = vid;
    }

    await this.getProductDetail(); // 进来先请求判断标签是否激活寄语, 没有激活去 LP 页面

    const step = storage('bless.step').get();
    this.step = Number(step) || 1;
    const mode = storage('bless.mode').get();
    this.mode = mode;

    callApp("setNavBarTitle", {
        type: "navbar.updateNavBarTitle",
        data: {
          key: "title",
          val: this.$t('feture.msg')
        }
      },
      "cbSetNavBarTitle",
      () => {},
      undefined
    );
  },
  methods: {
    async getProductDetail() {
      this.bless_loading = true;
      try {
        const rst = await VeTrueApi.getProductDetail({ vid: this.vid });
        // 判断标签是否激活寄语, 没有激活就去 LP 页面。 并且判断商品是否有退换货操作，有就去 LP 页面
        const active = R.pathOr('',['data', 'activate_state'])(rst);
        const returnType = R.pathOr(null, ['data', 'return_type'])(rst); // 退换货类型，null表示没有退换货操作，RETURN, BARTER 表示有退换货操作
        if (rst.status === 200 && active !== 'SUCCESS' || returnType !== null) {
          this.$router.push({ path: '/remark', query:{ vid: this.vid } });
          return;
        } else {
          await this.getBlessDetail(); // 请求判断是否已经填写数据
        }
        this.bless_loading = false;
      } catch (error) {
        this.bless_loading = false;
        const msg = R.pathOr('',['response', 'data', 'detail'])(error);
        this.$message({ message: msg, type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return;
      }
    },
    async getBlessDetail() {
      try {
        const rst = await VeTrueApi.getRecordDetail({ vid: this.vid });
        // 有数据且状态为成功时跳转到结果显示页面
        if (rst.status === 200 && rst.data.state === 'SUCCESS') {
          this.$router.push({ path: '/survey', query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB' } });
          return;
        } else {
          this.status = 0;
        }
      } catch (error) {
        const { status, data } = error.response;
        const code = R.pathOr(0, ['detail', 'code'])(data);
        if (status === 404) {
          this.status = 0;
        }
        // 当返回错误码 700008 时, 表示后台控制寄语信息为隐藏, 直接跳转到 LP 页面
        if (code === 700008) {
          this.$router.push({ path: '/remark', query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB' } });
          return;
        }
        return;
      }
    },
    hanldNext() {
      this.step++;
      storage('bless.step').set(2); // 暂存页码
    },
    handleSelectMode(mode) {
      this.mode = mode;
      storage('bless.mode').set(mode); // 暂存模版选择
    },
    handleBack() {
      this.step--;
      storage('bless.step').set(1); // 暂存页码

      this.bless_name = '';
      this.bless_text = ''; // 清空数据
      this.imageInfo = {};
      this.videoInfo = {};
      this.audioInfo = {};
    },
    handleGetFile(file) {
      if (file.operator === 'add') {
        const fileInfo = file.data[0].data;
        switch (file.type) {
          case 'video':
            this.videoInfo = fileInfo;
            break;
          case 'picture':
            this.imageInfo = fileInfo;
            break;
          case 'audio':
            this.audioInfo = fileInfo;
            break;
          default:
            break;
        }
      } else {
        switch (file.type) {
          case 'video':
            this.videoInfo = {};
            break;
          case 'picture':
            this.imageInfo = {};
            break;
          case 'audio':
            this.audioInfo = {};
            break;
          default:
            break;
        }
      }
    },
    beforeSubmit() {
      if (this.mode === 'picture' && R.isEmpty(this.imageInfo)) {
        this.$message({ message: this.$t('feture.img_empty_tip'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return false;
      }
      if (this.mode === 'video' && R.isEmpty(this.videoInfo)) {
        this.$message({ message: this.$t('feture.video_empty_tip'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return false;
      }
      if (this.mode === 'audio' && (R.isEmpty(this.audioInfo) || R.isEmpty(this.imageInfo))) {
        this.$message({ message: this.$t('feture.voice_tip'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return false;
      }
      if (this.mode !== 'audio' && !this.bless_name) {
        this.$message({ message: this.$t('feture.msg_empty'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return false;
      }
      if (this.mode !== 'audio' && !this.bless_text) {
        this.$message({ message: this.$t('feture.content_empty'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return false;
      }
      return true;
    },
    async handleSubmit() {
      const flag = this.beforeSubmit();
      if (!flag) return;

      const params = {
        mode: this.mode,
        vid: this.vid,
        name: this.bless_name,
        bless: this.bless_text,
        videoInfo: this.videoInfo,
        imageInfo: this.imageInfo,
        audioInfo: this.audioInfo,
      }
      const submitInfo = await getBlessSubmitInfo(params);

      try {
        const rst = await VeTrueApi.sendRemarkRecord({ vid: this.vid, data: submitInfo });
        if (rst.data.code === 0) {
          this.$router.push({ path: '/survey', query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB' } });
        } else {
          this.$message({ message: rst.data.message, type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
          return;
        }
      } catch (error) {
        const msg = R.pathOr('',['data', 'detail', 'msg'])(error.response);
        this.$message({ message: msg, type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return;
      }

    },
    handleSkipLP() {
      // 不想填写时跳去LP页面, 带上 from 参数
      this.$router.push({
        path: '/remark',
        query:{ vid: this.vid, plat: this.$route.query.plat || 'WEB', from: 'skip' },
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
}

.root {
  width: 80%;
  margin: 0 auto;
  padding: 30px 0;
  background-color: #FFF;
}

.back {
  margin-bottom: 1.5em;
}

.preamble {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  margin-bottom: 19px;
  font-size: 12px;
  color: rgba(169, 169, 176, 1);
}

.title {
  color: #282828;
  font-size: 20px !important;
  font-weight: bold;
  margin-bottom: 8px;
}

.right {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #282828;
  margin-top: -30px;
  & > img {
    width: 21px;
    height: 13px;
  }
}

.bless-name {
  height: 50px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-left: 10px;
  outline: none;
  background-color: rgba(249, 249, 249, 1);
}

.step {
  color: rgba(161, 161, 161, 1);
  font-size: 12px;
  font-weight: bold;
}

.steps {
  display: flex;
  width: 100%;
  margin-top: 13px;
  border-radius: 2px;
  margin-bottom: 27px;
  & > span {
    width: 100%;
    height: 4px;
    background-color: rgba(246, 246, 249, 1);
  }
}

.active {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.template, .mode {
  min-height: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  height: 150px;
  width: 100%;
  border-radius: 4px;
  background-color: #F7F7F7;
  margin-bottom: 30px;
}

.record {
  border-radius: 6px;
  background: linear-gradient(242deg, #00E6A4 0%, #00C86A 100%);
  & span {
    color: #FFF !important;
    font-weight: bold;
  }
}

.bless {
  padding-left: 1em;
  padding-top: 1em;
  outline: none;
}

.content {
  margin-top: 40px;
  display: flex;
  font-size: 14px;
  color: rgba(40, 40, 40, 1);
  font-weight: 400;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  & > img {
    width: 36px;
    height: 36px;
  }
  & > span {
    margin-top: 10px;
  }
}

.mode-content {
  & > img {
    width: 36px;
    height: 28px;
  }
}

.audio-content {
  & > img {
    width: 40px;
    height: 55px;
  }
}

.select {
  background: #F7F7F7;
  border: 2px solid #000000;
  & span {
    color: #282828;
    font-weight: bold;
  }
}

.button {
  height: 58px;
  width: 100%;
  background: #282828;
  border-radius: 2px;
  text-transform: uppercase;

  color: #F7F7F8;
  line-height: 58px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
</style>
