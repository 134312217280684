import VeTrueApi from '@/api/vetrue';

// 生成寄语记录动态表单需要的json结构数据
export const getBlessSubmitInfo = async (params: any) => {
  const detail = await VeTrueApi.getProductDetail({ vid: params.vid });
  const data = detail.data;

  const list: any = { num: 1 };
  if (data.custom_id) {
    list.type = 1;
    list.vidlist = [`${data.vid},${data.custom_id}`];
  } else {
    list.type = 0;
    list.vidlist = [data.vid];
  }

  const currentTime = new Date().getTime();
  const base = {
    vidlist: list,
    name: params.name,
    starttime: '',
    endtime: '',
    timestamp: currentTime,
  }

  const custom = {
    tracktime: currentTime,
    gps: {
      lat: 39.902798,
			lon: 116.401159,
			country: "中国",
			province: "北京",
			city: "北京市",
			address: "",
			street: "",
			precision: "65.0",
			district: ""
    },
    textmultiline_000: '', // 祝福语
    trackvideo: {}, // 上传的视频
    mediaaudio_000: {}, // 上传的音频
    trackimages: [] as any, // 上传的图片
  }

  switch (params.mode) {
    case 'picture':
      custom.textmultiline_000 = params.bless;
      custom.trackimages.push(params.imageInfo);
      break;
    case 'video':
      custom.textmultiline_000 = params.bless;
      custom.trackvideo = params.videoInfo;
      break;
    case 'audio':
      custom.trackimages.push(params.imageInfo);
      custom.mediaaudio_000 = params.audioInfo;
      break;
    default:
      break;
  }

  return { base, custom };
}



// {
// 	"base": {
// 		"vidlist": {
// 			"num": 0,
// 			"type": 0,
// 			"vidlist": []
// 		},
// 		"name": "",
// 		"starttime": "",
// 		"endtime": "",
// 		"timestamp": 1661133975476
// 	},
// 	"custom": {
// 		"tracktime": 1661133975511,
// 		"gps": {
// 			"lat": 39.902798,
// 			"lon": 116.401159,
// 			"country": "中国",
// 			"province": "北京",
// 			"city": "北京市",
// 			"address": "",
// 			"street": "",
// 			"precision": "65.0",
// 			"district": ""
// 		},
// 		"textmultiline_000": "",
// 		"trackvideo": {
// 			"filelocation": "",
// 			"filesize": "0",
// 			"filetype": "",
// 			"filename": "",
// 			"filehash": ""
// 		},
// 		"mediaaudio_000": {
// 			"filelocation": "",
// 			"filesize": "0",
// 			"filetype": "",
// 			"filename": "",
// 			"filehash": ""
// 		},
// 		"trackimages": [{
// 			"filelocation": "",
// 			"filesize": "0",
// 			"filetype": "",
// 			"filename": "",
// 			"filehash": ""
// 		}]
// 	}
// }


export const shortTxid = (txId: string, before: number, after: number) => {
  if (!txId) return '';
  if (txId.length <= 15) return txId;
  return `${txId.substring(0, before)}...${txId.substring(txId.length - after)}`;
}

// 函数防抖动, 滚动到底部
export const scroll = {
  isEnd: false,
  start(container: any, callback: any) {
    let timer: any = null;
    callback && container.addEventListener('scroll', () => {
      if(timer) {
        clearTimeout(timer)
      }
      // 函数防抖动
      timer = setTimeout(() => {
        // 浏览器向上滚动的高度
        const scrollTop = container.scrollTop;
        // 文档真实的高度
        const scrollHeight = container.scrollHeight;
        // 浏览器窗口（文档）的可视高度，就是肉眼可见的那部分真实高度
        const clientHeight = container.clientHeight;

        if (!this.isEnd && scrollHeight === scrollTop + clientHeight) {
          container.scrollTo(0,scrollTop - 30)
          callback()
        }
      },1000)
    })
  },
  end() {
    this.isEnd = true
  }
}

