<template>
  <div style="width: 100%; margin-bottom: 40px" v-if="fileList.length">

    <video style="width: 100%;background-color: #4A4A4A" controls="controls" v-if="filetype === 'video'" :src="fileList[0].url" />
    <el-image style="width: 100%;" class="upload-img" v-if="filetype === 'picture'" lazy :src="fileList[0].url" :fit="'contain'" />
    <audio style="width: 100%;" controls="controls" v-if="filetype === 'audio'" :src="fileList[0].url"/>

    <div class="image-info">
      <span>{{ fileList[0].name }}</span>
      <el-button type="danger" icon="el-icon-delete" size="mini" circle class="delete-btn" plain @click="handleDelete"/>
    </div>
  </div>
  <div class="container" :class="filetype === 'audio' ? 'record': ''" v-else>
    <input type="file" class="picker" ref="upload" :accept="getAccept(this.filetype)" @change="handleUpload" />
    <slot name="content"></slot>
  </div>
</template>

<script>
import VeTrueApi from '@/api/vetrue';
import * as R from 'ramda';

export default {
  name: 'FileUpload',
  props: ["filetype", "vid"],
  data: () => ({
    fileList: [],
    Loading: null,
  }),
  watch: {
    fileList: {
      handler(val) {
        if (val.length > 0) {
          this.$emit("confirm", { data: val, type: this.filetype, operator: 'add' });
        } else {
          this.$emit("confirm", { data: val, type: this.filetype, operator: 'remove' });
        }
      },
      deep: true
    }
  },
  methods: {
    getAccept(filetype) {
      let accept = 'image/*';
      switch (filetype) {
        case 'picture':
          accept = 'image/*';
          break;
        case 'video':
          accept = 'video/*';
          break;
        case 'audio':
          accept = 'audio/mpeg,audio/mp4,audio/ogg,audio/ac3';
          break;
        default:
          accept = 'image/*';
          break;
      }
      return accept;
    },
    beforeUpload(file) {
      if (this.filetype === 'audio' && file.type !== 'audio/mpeg') {
        this.$message({ message: this.$t('feture.audio_error'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return false;
      }
      if (!file) {
        this.$message({ message: this.$t('feture.reupload'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return false;
      }
      return true;
    },
    spliceUrl(location) {
      if (location) {
        return window.Config.bless_service_url + `/v1/public/service/attachment/${this.vid}/${location}`;
      }
    },
    startLoading() {
      this.Loading = this.openLoading(this.$t('feture.uploading'));
    },
    endLoading() {
      this.Loading.close();
    },
    async handleUpload(e) {
      const file = e.target.files[0];
      const flag = this.beforeUpload(file);
      if (!flag) return;

      this.startLoading();
      try {
        let data = await VeTrueApi.uploadFileByVid({
          vid: this.vid,
          file,
        });
        this.$nextTick(() => {
          let fileData = data.data;
          if (fileData.filehash) {
            this.imageBase64 = this.spliceUrl(fileData.filehash);
            this.fileList = [{
              url: this.imageBase64,
              name: fileData.filename,
              data: fileData,
            }];
          }
        });
        this.endLoading();
      } catch (error) {
        this.endLoading();
        this.$refs.upload.value = ''; // 解决文件上传失败后第二次重试不触发事件
        const { data } = error.response;
        const msg = R.pathOr('',['detail', 'msg'])(data);
        this.$message({ message: `${msg},` + this.$t('feture.again'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      }
    },
    handleDelete() {
      this.fileList = [];
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  height: 150px;
  width: 100%;
  border-radius: 4px;
  background-color: #F7F7F7;
  margin-bottom: 2em;
}

.picker {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.content {
  margin-top: 40px;
  display: flex;
  font-size: 1em;
  color: rgba(40, 40, 40, 1);
  font-weight: 400;
  align-items: center;
  flex-direction: column;
  & > span {
    margin-top: 10px;
  }
}

.record {
  border-radius: 6px;
  background: linear-gradient(242deg, #00E6A4 0%, #00C86A 100%);
  & span {
    color: #FFF !important;
    font-weight: bold;
  }
}

.image-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-top: 15px;
  & span {
    max-width: 200px;
    word-break: break-all;
  }
}

.delete-btn {
  font-size: 16px;
  margin-left: 20px;
}
</style>
